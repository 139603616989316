import { EmptyRequest } from '../grpc/generated/appointment_pb';
import { AppointmentProtoServicePromiseClient } from '../grpc/generated/appointment_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new AppointmentProtoServicePromiseClient(apiUrl);

const token = window.localStorage.getItem("token");
const metadata = { Authorization: "Bearer " + token };

async function listAppointments() {
    const request = new EmptyRequest();
    const response = await svc.listAppointments(request, metadata, {});
    return response.toObject();
}

async function getAppointment(request) {
    const response = await svc.getAppointment(request, metadata, {});
    return response.toObject();
}

async function addAppointment(request) {
    const response = await svc.addAppointment(request, metadata, {});
    return response.toObject();
}

async function todayMedicalCare(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.todayMedicalCare(request, metadata, {});

    return response.toObject();
}

async function listFutureMedicalCare (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listFutureMedicalCare(request, metadata, {});

    return response.toObject();
}

async function listPreviousMedicalCare (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listPreviousMedicalCare(request, metadata, {});

    return response.toObject();
}

async function listPatients (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listPatients(request, metadata, {});

    return response.toObject();
}

async function searchListPatients (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.searchListPatients(request, metadata, {});

    return response.toObject();
}

async function searchCpf (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.searchCpf(request, metadata, {});

    return response.toObject();
}

async function searchEmail (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.searchEmail(request, metadata, {});

    return response.toObject();
}

async function verifyConflict (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.verifyConflict(request, metadata, {});

    return response.toObject();
}

async function addAppointmentProfessional (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.addAppointmentProfessional(request, metadata, {});

    return response.toObject();
}

async function searchAppointments (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.searchAppointments(request, metadata, {});

    return response.toObject();
}

async function listMedicalCare (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listMedicalCare(request, metadata, {});

    return response.toObject();
}

async function conferCheckIn (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.conferCheckIn(request, metadata, {});

    return response.toObject();
}

async function professionalCheckIn (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.professionalCheckIn(request, metadata, {});

    return response.toObject();
}

async function updateOrderQueue (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.updateOrderQueue(request, metadata, {});

    return response.toObject();
}

async function listWaitingList (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listWaitingList(request, metadata, {});

    return response.toObject();
}

async function cancelAppointmentProfessional (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.cancelAppointmentProfessional(request, metadata, {});

    return response.toObject();
}

async function rescheduleAppointmentProfessional (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.rescheduleAppointmentProfessional(request, metadata, {});

    return response.toObject();
}

async function pausePatientCare (request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.pausePatientCare(request, metadata, {});

    return response.toObject();
}


export default {
    listAppointments,
    getAppointment,
    addAppointment,
    todayMedicalCare,
    listFutureMedicalCare,
    listPreviousMedicalCare,
    listPatients,
    searchListPatients,
    searchCpf,
    searchEmail,
    verifyConflict,
    addAppointmentProfessional,
    searchAppointments,
    listMedicalCare,
    conferCheckIn,
    professionalCheckIn,
    updateOrderQueue,
    listWaitingList,
    cancelAppointmentProfessional,
    rescheduleAppointmentProfessional,
    pausePatientCare,
}